.eva-grid-cells {
  display: grid;
  height: 100%;
  box-sizing: border-box;
  grid-gap: 2px;
}
.eva-grid-cells:before {
  content: attr(placeholder);
  position: absolute;
  top: 50%;
  left: 50%;
  /*color: rgba(0,0,0,0.1);*/
  font-size: 56px;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.eva-grid-cells .eva-grid_cell {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  /*color: rgba(0,0,0,0.2);*/
  outline: 2px #90A4AE dashed;
}
