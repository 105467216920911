.eva-file-field {
  display: flex;
  flex-direction: column;
  gap: 4px 8px;
  border-top: 2px solid #E5F1FF;
  border-bottom: 2px solid #E5F1FF;
  padding: 16px 0;
}
.eva-file-field .eva-file-field__header {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.eva-file-field .eva-file-field__header .eva-text {
  cursor: pointer;
}
.eva-file-field .eva-file-field__items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
