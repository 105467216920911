.eva-map-source-view-list {
  min-width: 500px;
  max-width: 500px;
  max-height: 300px;
  padding: 16px;
}
.eva-map-source-view-list .eva-btn {
  height: 40px;
  flex-shrink: 0;
}
.eva-map-source-view-list .eva-btn--secondary {
  /*background-color: #F6F8FF;
    border-color: #F6F8FF;*/
  justify-content: start;
  flex-grow: 1;
}
.eva-map-source-view-list .eva-btn--secondary:hover {
  /*background-color: #E5F1FF;
      border-color: #E5F1FF;*/
}
