
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-image {
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
  img {
    margin: auto;
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
  }
  &.eva-image--cover {
    img {
      margin: auto;
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: 100%;
    }
  }
  &.eva-image--round {
    border-radius: 50%;
    img {
      border-radius: 50%;
    }
  }
}
