
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-dialog {
  position: absolute;
  z-index: 2001;
  flex-shrink: 1;
  max-height: 90vh;
  max-width: 100vw;
  transform: translate(-50%, -50%);
  border-radius: (@eva-padding / 2);

  .eva-dialog__header {
    position: relative;
    align-items: center;
    padding: @eva-padding @eva-padding * 2;
    /*background-color: #FCFDFF;
    border-bottom: 2px solid #E5F1FF;*/
    /*color: rgba(0,0,0,0.6);*/
    font-size: 1.2rem;
    /*font-weight: bold;*/

    &.eva-dialog__header--small {
      padding-bottom: 0;
    }

    .eva-dialog__header--text_fixed {
      width: 350px;
    }

    .eva-btn {
      height: 34px;
    }

    span:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .eva-dialog__progress {
    width: 100%;
    height: @eva-padding;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    progress {
      width: 100%;
      height: 100%;
    }
  }

  .eva-dialog__content {
    padding: 0;
  }

  .eva-dialog__footer {
    padding: @eva-padding @eva-padding * 2;
    min-height: 50px;
  }

  &.eva-dialog--dialog {
    min-width: 500px;
    width: 0;
    top: 50%;
    left: 50%;
  }

  &.eva-dialog--full-screen {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100vh;
    transform: translate(0, 0);
    border-radius: 0;
  }

  &.eva-dialog--drawer {
    position: absolute;
    left: auto !important;
    right: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    max-height: 100vh !important;
    transform: translate(0, 0) !important;
    min-width: 300px;
    width: 40%;
    flex-grow: 0;
    border-radius: 0;
  }

  &.eva-dialog--dropdown {
    position: absolute;
    max-height: calc(100vh - 300px) !important;
    transform: translate(0, 0) !important;
    width: auto;

    .eva-dialog__header {
      background-color: unset !important;
    }
  }

  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2),
  0 8px 10px 1px rgba(0, 0, 0, .14),
  0 3px 14px 2px rgba(0, 0, 0, .12);
}
