.eva-map-preview {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 6px;
  padding: 0 16px 16px;
}
.eva-map-preview .eva-map-preview__canvas {
  /*background-color: #E5F1FF;*/
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.eva-map-preview canvas {
  border-radius: 6px;
}
