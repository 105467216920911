.eva-btn-commands {
  position: relative;
  margin-right: 1px;
}
.eva-btn-commands .eva-btn-commands--badge {
  position: absolute;
  border-radius: 50%;
  background-color: #0260CF;
  /*background-color: #0260CF;*/
  top: 4px;
  right: 4px;
  width: 10px;
  height: 10px;
}
.eva-btn-commands[disabled] .eva-btn-commands--badge {
  background-color: #0260CF;
  /*background-color: #F7F8FF;*/
}
