.eva-select-popup {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 300px;
}
.eva-select-popup ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.eva-select-popup ul li {
  height: 28px;
  line-height: 28px;
  cursor: pointer;
  padding: 0 16px;
}
.eva-select-popup ul li:hover {
  background-color: #0260CF9F;
}
.eva-select-popup ul li.eva-select-popup__selected {
  background-color: #0260CF;
}
