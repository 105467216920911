
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .eva-page__fullscreen-portal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2002;
    &.disabled {
      display: none;
    }
  }
}
