
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-repeater {
  height: 100%;
  display: flex;
  flex-direction: row;
  /*gap: @eva-padding;*/
  .eva-repeater__panel-text {
    max-width: 250px;
    align-self: center;
  }
  .eva-repeater__content {
    overflow: auto;
    flex-grow: 1;
    margin-bottom: @eva-padding;
    .v-progress-circular {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-50%);
      z-index: 100;
    }
  }
  &.eva-repeater--no-padding {
    gap: 0;
    .eva-repeater__content {
      margin-bottom: 0;
    }
  }
}
