.eva-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.eva-tabs--column {
  flex-direction: row;
}
.eva-tabs .eva-tabs__header {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.eva-tabs .eva-tabs__header--column {
  flex-direction: column;
}
.eva-tabs .eva-tabs__header--column .eva-tabs__header-item {
  padding: 29px 20px !important;
}
.eva-tabs .eva-tabs__header--border-bottom {
  border-bottom: 1px solid #212D39;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item {
  cursor: pointer;
  font-size: 16px;
  display: flex;
  color: #ACBBCB;
  align-items: center;
  padding: 16px;
  border-bottom: 2px solid transparent;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item:hover {
  border-color: #0260CF50;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item--column:hover {
  border-color: #3E4C5D;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item.eva-tabs__header-item--active {
  border-color: #0094FF;
  color: #FFFFFF !important;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item.eva-tabs__header-item--active-column {
  border-color: #3E4C5D;
  color: #FFFFFF !important;
}
.eva-tabs .eva-tabs__header .eva-tabs__header-item.eva-tabs__header-item--fullsize {
  width: 100%;
  justify-content: center;
}
.eva-tabs .eva-tabs__content {
  min-height: 0;
  height: 100%;
  width: 100%;
}
