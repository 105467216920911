
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-btn-legacy {
  border-radius: (@eva-padding)!important;
  padding: 0!important;
  &.eva-btn--light {
    color: white!important;
  }
  &.eva-btn--text {
    min-width: 36px!important;
    min-height: 36px!important;
    padding: 0 (@eva-padding) !important;
    //color: rgba(0,0,0,.54);
  }
  &.eva-btn--border-start {
    border-radius: (@eva-padding) 0 0 (@eva-padding)!important;
  }
  &.eva-btn--border-end {
    border-radius: 0 (@eva-padding) (@eva-padding) 0!important;
  }
  &.eva-btn--border-middle {
    border-radius: 0!important;
  }
  &.eva-btn--border-less {
    border: none!important;
  }
  &.eva-btn--icon {
    width: @eva-icon-size!important;
    height: @eva-icon-size!important;
    /*color: unset!important;*/
    &.eva-btn--outlined {
      width: 36px!important;
      height: 36px!important;
    }
    .v-icon {
      /*margin-right: (@eva-padding / 2)!important;
      font-size: (@eva-icon-size * 0.9)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
  &.eva-btn--icon-text {
    padding: 0 (@eva-padding) !important;
    .v-btn__content {
      flex-direction: row!important;
    }
    .v-icon {
      margin-right: (@eva-padding / 2)!important;
      /*font-size: (@eva-icon-size * 0.75)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
  &.eva-btn--text-icon {
    padding: 0 (@eva-padding) !important;
    .v-btn__content {
      flex-direction: row-reverse!important;
    }
    .v-icon {
      margin-left: (@eva-padding / 2)!important;
      /*font-size: (@eva-icon-size * 0.75)!important;*/
      height: 18px;
      font-size: 18px;
      width: 18px;
    }
  }
}
