.eva-image-field {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.eva-image-field .eva-image {
  opacity: 0.8;
  border-radius: 6px;
  position: relative;
}
.eva-image-field .eva-image img {
  border-radius: 6px;
}
.eva-image-field .eva-image .eva-image-field__commands {
  position: absolute;
  right: 4px;
  top: 4px;
}
.eva-image-field .eva-image .eva-image-field__commands .eva-btn {
  width: 32px;
  min-width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: #2A3746;
  color: #E4E9EF;
  border-radius: 6px;
  opacity: 0.7;
  padding: 4px;
  align-items: center;
}
