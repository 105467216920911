
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-select-date {
  .eva-textbox__inner {
    padding: 8px 16px;
    .eva-textbox__input {
      display: flex;
      align-items: center;
    }
  }
}
