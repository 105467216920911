.eva-tree {
  padding: 0;
  display: flex;
  flex-direction: column;
  /*color: #3E4C5D;*/
  min-height: 0;
  height: 100%;
}
.eva-tree .eva-tree-header {
  padding: 16px 16px 8px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  gap: 8px;
}
.eva-tree .eva-tree-header .eva-tree-header__context {
  width: 100%;
}
.eva-tree .eva-tree-header .eva-tree-header__tools {
  height: 50px;
  align-items: center;
}
.eva-tree .eva-tree-inner {
  padding: 0 16px;
}
