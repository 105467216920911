
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-video-teleport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  //Главный раздел
  .eva-video__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;

    //Контейнер с видео
    .eva-video__main-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      //Полупрозрачный фон для хедера
      .eva-video__header-background {
        height: 40px;
        width: 100%;
        position: absolute;
        background-color: black;
        opacity: .3;
      }

      .eva-video__header {
        height: 40px;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;

        //Отображение и выборк ОМ (слева)
        .eva-video__header-left {
          max-width: 65%;
          color: white;

          .v-btn {
            max-width: 100%;

            .v-btn__content {
              width: 100%;
              justify-content: start;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        //Часы и иконки (справа)
        .eva-video__header-right {
          display: flex;
          align-items: center;
          flex-direction: row;
          color: white;

          .eva-video__header-clock {
            text-align: center;
          }
        }
      }

      .eva-video__video {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
      }

      .eva-video__error {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      //Онлайн футер
      .eva-video__footer {
        height: 50px;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 @eva-padding;
      }
    }

    .eva-video__archive-timeline {
      position: relative;
      height: 21px;

      .timeline-list {
        width: 100%;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        position: absolute;

        .timeline-list-div {
          cursor: default;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
          -khtml-user-select: none; /* Konqueror HTML */
          -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently  supported by Chrome, Edge, Opera and Firefox */
        }
      }

      .timeline-marker {
        width: 2px;
        height: 21px;
        background-color: red;
        position: absolute;
        cursor: pointer;
      }

      //Архивный timeline
      .eva-video__archive-canvas {
        width: 100%;
        height: 21px;
        background-color: black;
      }
    }

    //Архивный футер
    .eva-video__archive-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: @eva-padding;

      .eva-video__archive-footer-left {
        .v-input__slider {
          max-width: 150px;
        }
        .v-input__slot {
          margin: 0;
          .v-slider {
            margin: 0;
            .v-slider__thumb::after {
              width: 32px;
              height: 32px;
            }
            .v-slider__tick {
              border-radius: 50%;
              background-color: #181F27;
              cursor: pointer;
            }
            .v-slider__ticks-container {
              background-color: #181F27;
            }
          }
        }
        .v-messages {
          display: none;
        }
      }

      .eva-video__archive-footer-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: @eva-padding;
      }
    }
  }

  //Боковая панель
  .eva-video__aside-panel {
    width: 140px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .eva-video__aside-panel-date {
      padding: 0 @eva-padding;

      .v-text-field__details {
        display: none;
      }
    }

    .eva-video__aside-panel-list {
      min-height: 0;
      padding: @eva-padding 0 0;
      .eva-default-table-cell {

        text-align: center;
      }
    }
  }

  video::-webkit-media-controls {
    display: none !important;
  }
}
.eva-video {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
