.eva-dialog2 {

    /* стили подвала и расположения кнопок */
    .footer-jc-sa {
        display: flex;
        justify-content: space-around;


        button {
            width: 50%;
        }
    }

    .footer-jc-fs {
        display: flex;
        justify-content: flex-start;

    }

    .footer-jc-fe {
        display: flex;
        justify-content: flex-end;

    }

    .buttonML10 {
        margin-left: 10px;
    }
}