.eva-repeater-header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: 8px 16px;
}
.eva-repeater-header .eva-repeater-header__list .eva-btn {
  /*max-width: 350px;*/
}
.eva-repeater-header .eva-repeater-header__caption {
  margin: 8px 0;
}
.eva-repeater-header .eva-repeater-header__tools {
  height: 50px;
  align-items: center;
  gap: 16px;
}
.eva-repeater-header .eva-repeater-header__context {
  max-width: 200px;
}
.eva-repeater-header .eva-repeater-header__icon {
  height: 40px;
  width: 40px;
  font-size: 40px;
}
