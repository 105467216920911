
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-map-preview {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 6px;
  padding: 0 (@eva-padding * 2) (@eva-padding * 2);
  .eva-map-preview__canvas {
    /*background-color: #E5F1FF;*/
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
  canvas {
    border-radius: 6px;
  }
}
