.eva-map-tools-panel {
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 10;
}
.eva-map-tools-panel .eva-map-tools-panel__inner {
  display: flex;
  gap: 8px;
  border-radius: 6px;
  opacity: 0.9;
  padding: 4px;
  align-items: center;
  max-width: 100%;
}
.eva-map-tools-panel .eva-map-tools-panel__inner .eva-btn {
  width: 32px;
  min-width: 32px;
  height: 32px;
  flex-shrink: 0;
  /*background-color: #FFFFFF;*/
}
