
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-repeater-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-items: left;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  gap: @eva-padding;
  padding: 0 16px 16px;

  .eva-pagination {
    display: flex;
    flex-direction: row;
    .eva-btn {
      border: none;
      &.eva-btn--secondary {
        /*background-color: #F6F8FF;
        color: #0260CF;*/
        /*font-weight: 600;*/
      }
      /*color: rgba(0,0,0,.54);*/

    }
  }

  .eva-repeater-footer__select {
    width : 130px;
    margin: 0!important;
    flex-grow: 0;
    border-radius: (@eva-padding)!important;
    input {
      display: none!important;
    }
    .v-input__append-inner {
      margin-top: 6px;
    }
    .v-input__slot {
      margin: 0!important;;
      min-height: 36px!important;
      padding: 0!important;
      background-color: #2A3746!important;
      fieldset {
        border-color: #2A3746;
      }
    }
    .v-icon {
      color: #E4E9EF !important;
    }
    .v-text-field__details {
      display: none!important;;
    }
    .v-select__selections {
      padding: 0!important;
      justify-content: center!important;
    }
    .v-icon {
      /*color: #3E4C5D!important;*/
    }
    .v-select__selection {
      margin: 0!important;
      /*color: #3E4C5D;*/
      /*font-weight: 600;*/
      color: #E4E9EF !important;
    }
  }
}
