
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-select-field {
  .eva-textbox__input {
    height: unset;
    min-height: 24px;
  }
  .eva-select-field__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    .eva-select-field__chip {
      background-color: #0260CF !important;
      color: white !important;
      height: 24px;
      margin: 0!important;
      .v-chip__close {
        margin: 0 !important;
      }
      .v-chip__content {
        gap: .75rem;
      }
      .v-icon {
        font-size: 16px !important;
      }
    }
  }
  &.eva-input--readonly {

  }
}
