/* TODO
  1. Если выставлен bold, то он подгружается позже
*/

html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  /*font-weight: 500;*/
}

.v-application {
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  code{
    background-color: unset;
    color: unset;
  }
  /*font-weight: 500;*/
}



.v-input {
  .v-input__append-inner {
    padding-left: 0!important;
  }
  &:not(.v-input--is-disabled) {
    .v-input__append-inner:nth-last-child(2) {
      order: 10;
    }
    .v-input__append-inner:nth-last-child(3) {
      order: 100;
    }
  }
}

.eva-tabs {
  .v-slide-group__next--disabled,
  .v-slide-group__prev--disabled {
    display: none;
  }
}

.eva-vuetify-tabs-fix {
  height: 100%;
  display: flex;
  flex-direction: column;
  .v-tabs-bar {
    flex-shrink: 0;
  }
  .v-tabs-items {
    height: 100%;
    .v-window-item {
      height: 100%;
    }
  }
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}




