
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-form-grid-layout {
  display: grid;
  > * {
    margin: @eva-padding!important;
  }
  .eva-input {
    margin: 0;
  }
  .eva-form-grid-layout__item {

  }
  &.eva-form-layout--border {
    /*padding: @eva-padding;*/
  }
  .eva-form-grid-layout__cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.2);
    outline: 1px #90A4AE dashed;
    min-height: 28px;
  }
  .eva-selected-input {
    outline: 2px solid #0260CF;
    box-sizing: border-box;
  }
}
