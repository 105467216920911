.eva-drawer {
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: relative;
  transition-property: width, height;
  transition-duration: 0.2s;
}
.eva-drawer .eva-drawer__inner {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-height: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  transition-property: width, height;
  transition-duration: 0.2s;
  cursor: pointer;
}
.eva-drawer .eva-drawer__inner:hover {
  overflow-y: auto;
}
.eva-drawer.eva-drawer--left {
  height: 100%;
}
.eva-drawer.eva-drawer--left .eva-drawer__inner {
  left: 0;
}
.eva-drawer.eva-drawer--right {
  height: 100%;
}
.eva-drawer.eva-drawer--right .eva-drawer__inner {
  right: 0;
}
.eva-drawer.eva-drawer--top {
  width: 100%;
}
.eva-drawer.eva-drawer--top .eva-drawer__inner {
  top: 0;
}
.eva-drawer.eva-drawer--bottom {
  width: 100%;
}
.eva-drawer.eva-drawer--bottom .eva-drawer__inner {
  bottom: 0;
}
.eva-drawer .eva-drawer__actions {
  /*padding-left: 5px;*/
  height: 50px;
}
.eva-drawer .eva-drawer__actions .eva-drawer__actions-custom {
  display: flex;
  align-items: center;
}
.eva-drawer.eva-drawer--close .eva-drawer__actions {
  padding-left: 5px;
}
