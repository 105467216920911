.eva-command-list {
  display: flex;
  gap: 8px;
}
.eva-command-list.eva-command-list--no-gap {
  gap: 0;
}
.eva-command-list.eva-command-list--column {
  flex-direction: column;
}
.eva-command-list.eva-command-list--column .eva-btn .v-btn__content {
  justify-content: flex-start;
}
