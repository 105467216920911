
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-drawer--close {
  .eva-list {
    .eva-list__item {
      .eva-list__item-header {
        display: none;
      }
      .eva-list__item-children {
        display: none;
      }
    }
  }
}
.eva-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  a {
    opacity: 1!important;
  }
  .eva-list__item {
    padding: 0 @eva-padding;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: inherit;
    text-decoration: none;
    .eva-list__item-children {
      display: flex;
      flex-direction: column;
      width: 100%;
      .eva-list__item-inner {
        margin-left: (@eva-padding * 3);
      }
    }
    .eva-list__item-inner {
      height: 32px;
      white-space: nowrap;
      /*border-radius: 6px;*/
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      gap: @eva-padding;
      width: 100%;
      &:hover {
        /*background-color: #E5F1FF;*/
      }
    }
    .eva-list__item-icon {

    }
    .eva-list__item-header {

    }
    &.eva-list__item--active {
      color: white;
      .eva-list__item-inner {
        background-color: #0260CF;
      }
    }
  }
  .eva-list__divider {
    flex: 0 0 auto;
    display: flex;
    width: 100%;
    /*height: @eva-border-width;*/
    /*background-color: @eva-border-color;*/
  }
}
