.eva-page--obsolete {
  width: 100%;
  height: 100%;
}
.eva-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.eva-page .eva-page__fullscreen-portal {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2002;
}
.eva-page .eva-page__fullscreen-portal.disabled {
  display: none;
}
