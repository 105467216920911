.eva-btn-legacy {
  border-radius: 8px !important;
  padding: 0!important;
}
.eva-btn-legacy.eva-btn--light {
  color: white!important;
}
.eva-btn-legacy.eva-btn--text {
  min-width: 36px!important;
  min-height: 36px!important;
  padding: 0 8px !important;
}
.eva-btn-legacy.eva-btn--border-start {
  border-radius: 8px 0 0 8px !important;
}
.eva-btn-legacy.eva-btn--border-end {
  border-radius: 0 8px 8px 0 !important;
}
.eva-btn-legacy.eva-btn--border-middle {
  border-radius: 0!important;
}
.eva-btn-legacy.eva-btn--border-less {
  border: none!important;
}
.eva-btn-legacy.eva-btn--icon {
  width: 34px !important;
  height: 34px !important;
  /*color: unset!important;*/
}
.eva-btn-legacy.eva-btn--icon.eva-btn--outlined {
  width: 36px!important;
  height: 36px!important;
}
.eva-btn-legacy.eva-btn--icon .v-icon {
  /*margin-right: (@eva-padding / 2)!important;
      font-size: (@eva-icon-size * 0.9)!important;*/
  height: 18px;
  font-size: 18px;
  width: 18px;
}
.eva-btn-legacy.eva-btn--icon-text {
  padding: 0 8px !important;
}
.eva-btn-legacy.eva-btn--icon-text .v-btn__content {
  flex-direction: row!important;
}
.eva-btn-legacy.eva-btn--icon-text .v-icon {
  margin-right: 4px !important;
  /*font-size: (@eva-icon-size * 0.75)!important;*/
  height: 18px;
  font-size: 18px;
  width: 18px;
}
.eva-btn-legacy.eva-btn--text-icon {
  padding: 0 8px !important;
}
.eva-btn-legacy.eva-btn--text-icon .v-btn__content {
  flex-direction: row-reverse !important;
}
.eva-btn-legacy.eva-btn--text-icon .v-icon {
  margin-left: 4px !important;
  /*font-size: (@eva-icon-size * 0.75)!important;*/
  height: 18px;
  font-size: 18px;
  width: 18px;
}
