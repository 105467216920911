.eva-tree-node .eva-tree-node__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  height: 40px;
}
.eva-tree-node .eva-tree-node__item:hover {
  /*background-color: #E5F1FF;*/
  cursor: pointer;
}
.eva-tree-node .eva-tree-node__item.eva-tree-node__item--selected {
  color: white;
  background-color: #0260CF !important;
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-arrow {
  font-size: 18px;
  width: 28px;
  height: 28px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid transparent;
  display: flex;
  flex-shrink: 0;
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-arrow:hover {
  /*border: 1px solid #d2e7ff;
        background-color: #E5F1FF;*/
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-arrow span {
  margin: auto;
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-icons {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-icons .mdi {
  font-size: 18px;
  width: 28px;
  height: 28px;
  display: flex;
  flex-shrink: 0;
}
.eva-tree-node .eva-tree-node__item .eva-tree-node__item-icons .eva-tree-node__item-icon--disabled {
  opacity: 0.5;
}
.eva-tree-node .eva-tree-node__children {
  padding-left: 16px;
}
