.eva-form-block-layout {
  display: flex;
  flex-direction: column;
}
.eva-form-block-layout > * {
  margin: 8px !important;
}
.eva-form-block-layout .eva-input {
  margin: 0;
}
.eva-form-block-layout.eva-form-layout--border {
  /*padding: @eva-padding;*/
}
