.eva-padding {
  padding: @eva-padding;
}

.eva-padding-top {
  padding-top: @eva-padding;
}

.eva-padding-bottom {
  padding-bottom: @eva-padding;
}

.eva-padding-left {
  padding-left: @eva-padding;
}

.eva-padding-right {
  padding-right: @eva-padding;
}

.eva-padding-x {
  padding-left: @eva-padding;
  padding-right: @eva-padding;
}

.eva-padding-y {
  padding-top: @eva-padding;
  padding-bottom: @eva-padding;
}

.eva-padding-2 {
  padding: (@eva-padding * 2);
}

.eva-padding-2-top {
  padding-top: (@eva-padding * 2);
}

.eva-padding-2-bottom {
  padding-bottom: (@eva-padding * 2);
}

.eva-padding-2-left {
  padding-left: (@eva-padding * 2);
}

.eva-padding-2-right {
  padding-right: (@eva-padding * 2);
}

.eva-padding-2-x {
  padding-left: (@eva-padding * 2);
  padding-right: (@eva-padding * 2);
}

.eva-padding-2-y {
  padding-top: (@eva-padding * 2);
  padding-bottom: (@eva-padding * 2);
}

.eva-padding--clear {
  padding: 0!important;
}

.eva-margin {
  margin: @eva-padding;
}

.eva-margin-top {
  margin-top: @eva-padding;
}

.eva-margin-bottom {
  margin-bottom: @eva-padding;
}

.eva-margin-left {
  margin-left: @eva-padding;
}

.eva-margin-right {
  margin-right: @eva-padding;
}

.eva-margin-x {
  margin-left: @eva-padding;
  margin-right: @eva-padding;
}

.eva-margin-y {
  margin-top: @eva-padding;
  margin-bottom: @eva-padding;
}

.eva-margin-2 {
  margin: (@eva-padding * 2);
}

.eva-margin-2-top {
  margin-top: (@eva-padding * 2);
}

.eva-margin-2-bottom {
  margin-bottom: (@eva-padding * 2);
}

.eva-margin-2-left {
  margin-left: (@eva-padding * 2);
}

.eva-margin-2-right {
  margin-right: (@eva-padding * 2);
}

.eva-margin-2-x {
  margin-left: (@eva-padding * 2);
  margin-right: (@eva-padding * 2);
}

.eva-margin-2-y {
  margin-top: (@eva-padding * 2);
  margin-bottom: (@eva-padding * 2);
}

.eva-margin--clear {
  margin: 0!important;
}
