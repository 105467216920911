.eva-metadata-designer-item-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 8px;
}
.eva-metadata-designer-item-component .eva-text--header {
  padding: 0 8px 8px;
}
.eva-metadata-designer-item-component .eva-overflow {
  height: 100%;
  overflow: auto;
  min-height: 0;
}
.eva-metadata-designer-item-component .eva-field {
  /*color: #3E4C5D;*/
  padding: 0 8px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.eva-metadata-designer-item-component .eva-field.eva-field--even {
  /*background-color: #F6F8FF;*/
}
.eva-metadata-designer-item-component .eva-field.eva-field--error {
  color: #E23244 !important;
}
.eva-metadata-designer-item-component .eva-field.eva-field--error .eva-text {
  color: #E23244 !important;
}
.eva-metadata-designer-item-component .eva-field:hover {
  /*background-color: #E1F5FE;*/
  cursor: pointer;
}
.eva-metadata-designer-item-component .v-window {
  flex-grow: 1;
  display: flex;
  height: calc(100% - 48px);
}
.eva-metadata-designer-item-component .v-window .v-window__container {
  height: 100%;
  width: 100%;
}
.eva-metadata-designer-item-component .v-window .v-window__container .v-window-item {
  height: 100%;
}
