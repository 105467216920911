*::-webkit-scrollbar {
  width: @eva-padding * 2;
  height: @eva-padding * 2;
  background-color: transparent;
}
*::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #212D39;
  background-clip: padding-box;
  border-radius: 9999px;
  border: 4px solid #00000000;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}
*::-webkit-scrollbar-corner {
  display: none;
}

