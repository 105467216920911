.backGround {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #2121217e;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.bodyDialog {
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0px 16px;
}
