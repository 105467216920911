.eva-item-select-component {
  padding: 16px;
  max-height: 400px;
}
.eva-item-select-component .eva-btn--secondary {
  /*background-color: #F6F8FF;
    border-color: #F6F8FF;*/
  justify-content: start;
  flex-grow: 1;
}
.eva-item-select-component .eva-btn--secondary:hover {
  /*background-color: #E5F1FF;
      border-color: #E5F1FF;*/
}
