
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-image-field {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  .eva-image {
    opacity: 0.8;
    border-radius: 6px;
    position: relative;
    img {
      border-radius: 6px;
    }
    .eva-image-field__commands {
      position: absolute;
      right: 4px;
      top: 4px;
      .eva-btn {
        width: 32px;
        min-width: 32px;
        height: 32px;
        flex-shrink: 0;
        background-color: #2A3746;
        color: #E4E9EF;
        border-radius: 6px;
        opacity: 0.7;
        padding: 4px;
        align-items: center;
      }
    }
  }
}
