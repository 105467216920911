
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.tcF3 {
  color: #7B8B9D;
}

.tcOrange {
  color: #F39324;
}

.iconsPadding {
  padding-right: 4px;
  margin-left: 15px;
}

.indexBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;

  .checkEvent {
    text-align: center;
    /* flex: 0 0 45px; */
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef00;
    cursor: pointer;
    font-size: 1.3rem;
  }
}

.indexBlock_selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;

  outline: 1px solid #26A4FF;
  background-color: #435973;

  .checkEvent {
    text-align: center;
    /* flex: 0 0 45px; */
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef;
    cursor: pointer;
    font-size: 1.3rem;
  }
}

.blockFolder {
  /* flex: 1 1 20%; */
  width: calc(100% - 45px);
  cursor: pointer;
}

.indexBlock:hover {
  outline: 1px solid #26A4FF;

  .checkEvent {
    width: 45px;
    margin: 0px 0px;
    color: #e4e9ef;
  }
}

.indexBlock::selection {
  background-color: #E4E9EF;
}

.blockMargin {
  margin-left: 10px;
  width: 10px;
  border: 1px black;
  background-color: aqua;
}

.eva-tree-node__item--selected {
  color: white;
  background-color: #2A3746 !important;
}

.eva-tree-node__item--selected>.eva-tree-node__children {
  background-color: #324459 !important;
}



/* .eva-tn-index {
  padding-left: 15px;
}

.eva-tree-node__children {
  margin-left: 5px;
}

.eva-tree-node-r:hover {
  border: solid #26A4FF;
} */

.eva-tree-node-r {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.eva-data-name {
  margin-left: 5px;
}
