.eva-layout {
  display: flex;
  width: 100%;
  /*height: 100%;*/
  overflow: hidden;
}
.eva-layout.eva-layout--row {
  flex-direction: row;
}
.eva-layout.eva-layout--column {
  flex-direction: column;
}
.eva-layout.eva-layout--fill {
  flex: 1;
}
.eva-layout.eva-layout--gap {
  gap: 8px;
}
.eva-layout.eva-layout--no-shrink {
  flex-shrink: 0;
}
.eva-layout.eva-layout--scroll {
  flex: 1;
  overflow: auto;
}
.eva-layout.eva-layout--scroll-hide {
  flex: 1;
  overflow: hidden;
}
.eva-layout.eva-layout--scroll-hide:hover {
  overflow-y: auto;
}
