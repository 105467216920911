
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-map-source-view-list {
  min-width: 500px;
  max-width: 500px;
  max-height: 300px;
  padding: (@eva-padding * 2);
  .eva-btn {
    height: 40px;
    flex-shrink: 0;
  }
  .eva-btn--secondary {
    /*background-color: #F6F8FF;
    border-color: #F6F8FF;*/
    justify-content: start;
    flex-grow: 1;
    &:hover {
      /*background-color: #E5F1FF;
      border-color: #E5F1FF;*/
    }
  }
}
