.eva-textarea {
  &__label:extend(.eva-base-field__label) {

  }

  &__inner:extend(.eva-base-field__inner) {
    padding: 12px;

    @media @minHover {
      &:hover {
        .eva-textarea {
          &__background {
            /*background-color: @fieldBgHoverColor;*/
          }
        }
      }
    }
  }

  &__field:extend(.eva-base-field__field) {
    height: 100%;
    resize: none;
    .eva-base-scrollbar();
    &:focus {
      & + .eva-textarea {
        &__background {
          /*background-color: @cLight;*/
        }
      }
    }

    //&:disabled {
    //  & + .eva-textarea {
    //    &__background {
    //      background: @cLight;
    //      border: 1px solid @fieldBgColor;
    //    }
    //  }
    //  & ~ .eva-textarea {
    //    &__buttons {
    //      opacity: 0.5;
    //      pointer-events: none;
    //    }
    //  }
    //}
  }

  &__background:extend(.eva-base-field__background) {

  }

  &__buttons:extend(.eva-base-field__buttons) {
    margin-top: 2px;
  }

  &__button:extend(.eva-base-field__button ) {

  }

  &--outline {
    .eva-textarea {
      &__background {
        /*background: @cWhite;
        border: 1px solid @cBlackText;*/
      }

    }
  }

  &--error {
    .eva-textarea {
      &__background {
        .eva-base-field--error__background();
      }
    }
  }

  &--disabled {
    .eva-textarea {
      &__background {
        .eva-base-field--disabled__background()
      }

      &__inner {
        @media @minHover {
          &:hover {
            .eva-textarea {
              &__background {
                /*background-color: @cLight;*/
              }
            }
          }
        }
      }

      &__buttons {
        .eva-base-field--disabled__buttons();
      }
    }
  }
}
