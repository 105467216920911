.eva-chip {

  &--small {
    min-width: 90px;
  }

  &--medium {
    min-width: 185px;
  }

  &--large {
    min-width: 200px;
  }

  &__inner {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: @cBlue;
    border-radius: 15px;
    padding: 6px 10px;
    color: @cWhite;
    transition: background-color @transition-time;

    @media @minHover {
      &:hover {
        background: @cBlueHover;
      }
    }
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
  }

  &__buttons {
    display: flex;
    flex-shrink: 0;
    margin-left: 5px;
  }

  &__button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    padding: 0;
    color: @formFon;
    cursor: pointer;
  }

  &--disabled {

    .eva-chip {
      &__inner {
        background-color: @cLight;
        color: rgba(@cBlackText, 0.5);
        cursor: default;
      }

      &__button {
        color: rgba(@cBlackText, 0.5);
        pointer-events: none;
      }
    }
  }

  &--flat {

    .eva-chip {
      &__inner {
        background-color: transparent;
        color: @cBlackText;

        @media @minHover {
          &:hover {
            background: @chipFlatHover;
          }
        }
      }

      &__button {
        color: @cBlackText;
      }
    }

    &.eva-chip--disabled {
      .eva-chip {
        &__inner {
          background-color: transparent;
          color: rgba(@cBlackText, 0.5);
        }

        &__button {
          color: rgba(@cBlackText, 0.5);
        }
      }
    }
  }
}
