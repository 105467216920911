
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-text-area-field-legacy {
  .v-input__slot {
    background-color: #E5F1FF!important;
  }
  .v-input__append-inner {
    position: absolute!important;
    top: 0!important;;
    right: 0!important;;
  }
  textarea {
    margin-bottom: 4px!important;
    padding-right: 4px!important;;
    margin-right: 6px!important;
  }
}
