.eva-media-text {
  display: flex;
  gap: 8px;
  flex: 0 0 auto;
}
.eva-media-text .eva-icon {
  width: 32px;
  height: 32px;
  display: flex;
  margin: auto 0;
  flex: 0 0 auto;
}
.eva-media-text .eva-image {
  width: 32px;
  height: 32px;
  display: flex;
  margin: auto 0;
  flex: 0 0 auto;
}
