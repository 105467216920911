
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-catalog-ref-field-legacy {
  position: relative;

  .v-input__slot {
    background-color: #E5F1FF!important;
  }

  .v-input {
    .v-input__append-inner:first-child {
      order: 100;
    }
  }

  .eva-catalog-ref-field__chip {
    height: 24px;
    margin: 2px!important;
    .v-chip__content {
      gap: .75rem;
    }
    .v-icon {
      font-size: 24px!important;
    }
    background-color: #0260CF!important;
    color: white!important;
  }

  .eva-catalog-ref-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: @eva-padding;
  }

  .eva-catalog-ref-search-field {
    flex-grow: 1;
    flex-shrink: 1;
  }

  .eva-catalog-ref-actions {
    flex-shrink: 1;
  }

  .eva-catalog-ref__list-item {
    padding-left: @eva-padding * 2;
    padding-top: (@eva-padding / 2);
    padding-bottom: (@eva-padding / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .eva-catalog-ref__list-item__active {
    background-color: #d2d8dd;
  }

  .eva-catalog-ref__list-item__actions {
    display: flex;
    flex-direction: row;
    gap: (@eva-padding / 1);
    padding-right: @eva-padding;
  }

  .eva-catalog-ref-list-footer {
    display: flex;
    justify-content: center;
  }

  .v-select__selections {
    input {
      display: none;
    }
  }
}
