.eva-catalog-ref-field-legacy {
  position: relative;
}
.eva-catalog-ref-field-legacy .v-input__slot {
  background-color: #E5F1FF !important;
}
.eva-catalog-ref-field-legacy .v-input .v-input__append-inner:first-child {
  order: 100;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-field__chip {
  height: 24px;
  margin: 2px!important;
  background-color: #0260CF !important;
  color: white!important;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-field__chip .v-chip__content {
  gap: 0.75rem;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-field__chip .v-icon {
  font-size: 24px!important;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-search-field {
  flex-grow: 1;
  flex-shrink: 1;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-actions {
  flex-shrink: 1;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref__list-item {
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref__list-item__active {
  background-color: #d2d8dd;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref__list-item__actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-right: 8px;
}
.eva-catalog-ref-field-legacy .eva-catalog-ref-list-footer {
  display: flex;
  justify-content: center;
}
.eva-catalog-ref-field-legacy .v-select__selections input {
  display: none;
}
