.eva-day-time-field .eva-day-time-field-wrapper {
  display: flex;
  gap: 8px;
}
.eva-day-time-field .eva-day-time-field-days {
  width: 120px;
  height: 57px;
}
.eva-day-time-field .eva-day-time-field-time {
  width: 100%;
}
.eva-day-time-field .eva-day-time-field-time .v-text-field__details {
  display: none;
}
