
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-video-event-list {
  .eva-video__aside-panel {
    width: 200px;
    display: flex;
    flex-direction: column;

    .eva-video__aside-panel-date {
      padding: 10px;

      .v-text-field__details {
        display: none;
      }
    }

    .eva-video__aside-panel-list {
      max-height: 100%;
      overflow-y: scroll;

      .v-list {
        padding: 0;

        .v-list-item {
          display: flex;
          justify-content: center;
        }
      }
    }

    .eva-repeater {
      padding: 0;
      display: block;

      .eva-repeater__content {
        margin-bottom: 0;

        table {
          padding: 0;
        }

        .eva-default-table-cell {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
