.eva-btn {
  position: relative;
}
.eva-btn a {
  text-decoration: underline;
}
.eva-btn .eva-btn--badge {
  position: absolute;
  border-radius: 50%;
  background-color: #0260CF;
  /*background-color: #0260CF;*/
  top: 4px;
  right: 4px;
  width: 10px;
  height: 10px;
}
.eva-btn[disabled] .eva-btn--badge {
  background-color: #0260CF;
  /*background-color: #F7F8FF;*/
}
