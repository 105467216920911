
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-map {
  width: 100%;
  height: 100%;
  position: relative;
  /*background-color: #E5F1FF;*/

  .eva-map-path-panel {
    .eva-map-tools-panel__inner {
      padding: @eva-padding;
    }
  }

  .eva-map__tooltip {
    position: absolute;
    padding: @eva-padding;
    gap: @eva-padding;
    border-radius: 6px;
    border: 1px solid #BDBDBD;
    background: #BDBDBD;
    color: #3E4C5D;
    box-shadow: 0px 3px 10px 0px #3E4C5D;
    z-index: 99;
  }

  .eva-map__group {
    position: absolute;
    display: flex;
    padding: @eva-padding * 1.5;
    gap: @eva-padding;
    border-radius: 6px;
    border: 1px solid #576D88;
    background-color: #3E4C5D;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.26);
    z-index: 100;
    flex-wrap: wrap;
    max-width: 300px;
    span {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 4px;
      border-radius: 16px;
      overflow: hidden;
      z-index: 1;
      img {
        width: 24px;
        height: 24px;
      }
      &:hover {
        cursor: pointer;
        outline: 4px solid #ACBBCB;
      }
      &.eva-map__group--selected {
        outline: 8px solid #ACBBCB;
      }
    }
  }

  .eva-map__canvas {
    width: 100%;
    height: 100%;
  }
  
  &.eva-map--fullscreen {
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 199;
  }

  &.eva-map--loading {
    .eva-map__canvas {
      opacity: 0;
    }
  }
}
