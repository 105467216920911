.eva-text-area-field-legacy .v-input__slot {
  background-color: #E5F1FF !important;
}
.eva-text-area-field-legacy .v-input__append-inner {
  position: absolute!important;
  top: 0!important;
  right: 0!important;
}
.eva-text-area-field-legacy textarea {
  margin-bottom: 4px!important;
  padding-right: 4px!important;
  margin-right: 6px!important;
}
