.eva-text {
  align-items: center;
  margin: auto 0;
  text-transform: none!important;
}
.eva-text.eva-text--bolder {
  font-weight: bolder;
}
.eva-text.eva-text--bold {
  font-weight: bold;
}
.eva-text.eva-text--italic {
  font-style: italic;
}
.eva-text.eva-text--no-wrap {
  white-space: nowrap;
}
.eva-text.eva-text--center {
  margin: auto;
}
.eva-text.eva-text--header {
  /*font-weight: 600;*/
  font-size: 16px;
  align-self: flex-start;
  line-height: 16px;
  /*color: #0260CF;*/
}
.eva-text.eva-text--subheader {
  font-weight: 400;
  font-size: 14px;
  align-self: flex-start;
  line-height: 14px;
  /*color: #0260CF;*/
}
