.folderBody {
  overflow: auto;
}
.blockIndex {
  margin: 15px;
}
.widthLine {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #3E4C5D;
  cursor: pointer;
}
.widthLine span {
  font-size: 1.4rem;
  margin-right: 15px;
  cursor: pointer;
}
.itemFolder {
  display: flex;
  align-items: center;
  border-radius: 6px;
  /* background-color: #3E4C5D; */
  margin: 5px;
  padding: 5px;
  cursor: pointer;
}
.itemFolder .textBlock {
  width: 90%;
  padding: 5px;
}
.itemFolder .iconBlock {
  width: 10%;
  text-align: end;
}
.itemFolder:hover {
  outline: 1px solid #26A4FF;
  /* background-color: #2A3746; */
}
.itemFolder-Selected {
  background-color: #2A3746;
}
