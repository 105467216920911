
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-video-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  overflow: hidden;
  position: relative;
}
.eva-video-player__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  position: relative;
  .eva-video-player__header {
    height: @eva-header;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 @eva-padding;
    background-color: black;
    opacity: 0.3;
    z-index: 1;
  }
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    /*object-fit: fill;*/
  }
  .eva-video-player__panel {
    display: flex;
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: @eva-padding;
    z-index: 2000;
  }
  .eva-video__error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .eva-icon {
      font-size: 34px;
    }
  }
}
