
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-grid-cells {
  display: grid;
  height: 100%;
  box-sizing: border-box;
  grid-gap: @eva-grid-gap;
  &:before {
    content: attr(placeholder);
    position: absolute;
    top: 50%;
    left: 50%;
    /*color: rgba(0,0,0,0.1);*/
    font-size: 56px;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .eva-grid_cell {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    /*color: rgba(0,0,0,0.2);*/
    outline: @eva-grid-gap #90A4AE dashed;
  }
}
