.eva-input {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 8px 0;
  /*height: 56px;*/
}
.eva-input.eva-textbox--readonly .eva-textbox__inner {
  opacity: 0.6;
}
.eva-input > .eva-textbox__inner {
  flex-grow: 1;
  min-height: 40px;
}
.eva-input > .eva-textbox__label {
  margin-bottom: 0;
}
.eva-input.eva-textbox--preview {
  cursor: default !important;
}
.eva-input.eva-textbox--preview .eva-textbox__inner {
  padding: 0 !important;
}
.eva-input.eva-textbox--preview .eva-textbox__inner .eva-textbox__background {
  background-color: transparent !important;
}
.eva-input.eva-textbox--preview .eva-textbox__inner .eva-textbox__input {
  cursor: default !important;
}
.eva-input .eva-textbox__button--error {
  color: #CC1D26;
}
.eva-input .eva-textbox__button--error:hover {
  color: #E23244;
}
.eva-input .eva-textbox__button--primary {
  color: #0260CF;
}
.eva-input .eva-textbox__button--primary:hover {
  color: #006CEB;
}
.eva-input .eva-textbox__background {
  /*color: #3E4C5D;*/
  font-size: 14px;
  font-family: inherit;
  padding: 8px 12px;
  line-height: 26px;
  opacity: 0.8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
