.eva-field-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.eva-field-wrapper .eva-field-wrapper__content {
  width: 100%;
  height: 100%;
}
.eva-field-wrapper .eva-field-wrapper__info {
  margin-top: 7px;
  margin-left: 5px;
}
