.eva-select-field .eva-textbox__input {
  height: unset;
  min-height: 24px;
}
.eva-select-field .eva-select-field__chips {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.eva-select-field .eva-select-field__chips .eva-select-field__chip {
  background-color: #0260CF !important;
  color: white !important;
  height: 24px;
  margin: 0!important;
}
.eva-select-field .eva-select-field__chips .eva-select-field__chip .v-chip__close {
  margin: 0 !important;
}
.eva-select-field .eva-select-field__chips .eva-select-field__chip .v-chip__content {
  gap: 0.75rem;
}
.eva-select-field .eva-select-field__chips .eva-select-field__chip .v-icon {
  font-size: 16px !important;
}
