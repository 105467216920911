
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-metadata-designer-item-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: @eva-padding;
  .eva-text--header {
    padding: 0 @eva-padding @eva-padding;
  }
  .eva-overflow {
    height: 100%;
    overflow: auto;
    min-height: 0;
  }
  .eva-field {
    /*color: #3E4C5D;*/
    padding: 0 @eva-padding;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    &.eva-field--even {
      /*background-color: #F6F8FF;*/
    }
    &.eva-field--error {
      color: #E23244!important;
      .eva-text {
        color: #E23244!important;
      }
    }
    &:hover {
      /*background-color: #E1F5FE;*/
      cursor: pointer;
    }
  }

  .v-window {
    flex-grow: 1;
    display: flex;
    height: calc(100% - 48px);
    .v-window__container {
      height: 100%;
      width: 100%;
      .v-window-item {
        height: 100%;
      }
    }
  }
}
