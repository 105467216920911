.eva-display-none {
  display: none!important;
}

.eva-opacity-none {
  opacity: 0!important;
  position: absolute!important;
  left: 50%!important;
  top: 50%!important;;
}

.eva-cursor-pointer {
  cursor: pointer;
}
