.eva-file-attach-field .eva-file-attach-field-btn {
  width: 100%;
  margin-top: 12px;
}
.eva-file-attach-field .eva-file-attach-field-files {
  display: flex;
  flex-direction: column;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-file .eva-file-attach-field-files-file-name {
  display: flex;
  align-items: center;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file {
  width: 100px;
  height: 76px;
  position: relative;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file .eva-file-attach-field-files-file-delete-btn {
  position: absolute;
  right: -5px;
  top: 0;
  border-radius: 50%;
  background-color: #495A6F;
}
.eva-file-attach-field .eva-file-attach-field-files .eva-file-attach-field-files-images .eva-file-attach-field-files-file .eva-file-attach-field-files-file-delete-btn:hover {
  cursor: pointer;
}
