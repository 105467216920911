
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-btn {
  position: relative;
  a {
    text-decoration: underline;
  }
  .eva-btn--badge {
    position: absolute;
    border-radius: 50%;
    background-color: #0260CF;
    /*background-color: #0260CF;*/
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
  }
  &[disabled] {
    .eva-btn--badge {
      background-color: #0260CF;
      /*background-color: #F7F8FF;*/
    }
  }
}
