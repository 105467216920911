
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-map-tools-panel {
  position: absolute;
  display: flex;
  overflow: hidden;
  z-index: 10;
  .eva-map-tools-panel__inner {
    display: flex;
    gap: 8px;
    border-radius: 6px;
    opacity: 0.9;
    padding: (@eva-padding / 2);
    align-items: center;
    max-width: 100%;
    .eva-btn {
      width: 32px;
      min-width: 32px;
      height: 32px;
      flex-shrink: 0;
      /*background-color: #FFFFFF;*/
    }
  }
}
