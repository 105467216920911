
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-repeater-header {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  padding: @eva-padding (@eva-padding * 2);

  .eva-repeater-header__list {
    .eva-btn {
      /*max-width: 350px;*/
    }
  }

  .eva-repeater-header__caption {
    margin: @eva-padding 0;
  }

  .eva-repeater-header__tools {
    height: @eva-header;
    align-items: center;
    gap: (@eva-padding * 2);
  }

  .eva-repeater-header__context {
    max-width : 200px;
  }

  .eva-repeater-header__icon {
    height: 40px;
    width: 40px;
    font-size: 40px;
  }
}
