.eva-table-field {
  min-height: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
.eva-table-field .eva-table {
  padding-left: 0;
  padding-right: 0;
  border-radius: 6px;
}
.eva-table-field .eva-table > .eva-layout {
  border-radius: 6px;
}
.eva-table-field .eva-table.eva-table-field--dense .eva-repeater-header {
  flex-direction: row;
}
.eva-table-field .eva-table.eva-table-field--dense .eva-repeater-header .eva-repeater-header__caption {
  margin: auto;
}
.eva-table-field .eva-table.eva-table-field--dense .eva-repeater-header .eva-text--subheader {
  flex-grow: 1;
}
.eva-table-field .eva-table.eva-table-field--dense .eva-repeater-header .eva-repeater-header__tools {
  width: auto;
}
.eva-table-field .eva-table.eva-table-field--short .eva-repeater-header {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.eva-table-field .eva-table.eva-table-field--short .eva-repeater-header .eva-repeater-header__filter {
  display: none;
}
.eva-table-field .eva-table.eva-table-field--short .eva-repeater-header .eva-spacer {
  display: none;
}
.eva-table-field .eva-table.eva-table-field--short .eva-repeater-header .eva-repeater-header__list {
  width: 100%;
}
.eva-table-field .eva-table.eva-table-field--short .eva-repeater-header .eva-repeater-header__list .eva-btn {
  width: 100%;
}
