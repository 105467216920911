.eva-files-table {
  display: flex;
  width: 100%;
  height: 100%;
}
.eva-files-table .eva-images-widget--filter-group {
  margin-right: 32px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.eva-files-table .eva-images-widget--filter-group .eva-images-widget--filter-group-item {
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 8px;
  border: 2px solid transparent;
  cursor: pointer;
}
.eva-files-table .eva-images-widget--filter-group .eva-images-widget--filter-group-item:hover {
  border-color: #E4E9EF;
}
.eva-files-table .eva-images-widget--filter-group .eva-images-widget--filter-group-item.eva-images-widget--filter-group-item--active {
  border: 2px solid #0260CF;
}
