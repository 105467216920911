
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-drawer {
  display: flex;
  flex-direction: column;
  z-index: 100;
  position: relative;
  transition-property: width, height;
  transition-duration: 0.2s;
  .eva-drawer__inner {
    display: flex;
    flex-direction: column;
    position: absolute;
    min-height: 0;
    z-index: 1000;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition-property: width, height;
    transition-duration: 0.2s;
    cursor: pointer;
    &:hover {
      overflow-y: auto;
    }
  }
  &.eva-drawer--left {
    height: 100%;
    .eva-drawer__inner {
      left: 0;
    }
  }
  &.eva-drawer--right {
    height: 100%;
    .eva-drawer__inner {
      right: 0;
    }
  }
  &.eva-drawer--top {
    width: 100%;
    .eva-drawer__inner {
      top: 0;
    }
  }
  &.eva-drawer--bottom {
    width: 100%;
    .eva-drawer__inner {
      bottom: 0;
    }
  }
  .eva-drawer__actions {
    /*padding-left: 5px;*/
    height: @eva-header;
    .eva-drawer__actions-custom {
      display: flex;
      align-items: center;
    }
  }
  &.eva-drawer--close {
    .eva-drawer__actions {
      padding-left: 5px;
    }
  }
}
