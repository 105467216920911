.eva-icon {
  font-size: 18px;
  justify-content: center;
  letter-spacing: normal;
}
.eva-icon:before {
  width: 100%;
  height: 100%;
  text-align: center;
}
.eva-icon--clickable {
  cursor: pointer;
}
