.set-border(@theme, @settings) {
  .eva-theme-@{theme} {
    .eva-border {
      border: @eva-border-width solid @settings[color];
    }
    .eva-border-x {
      border-left: @eva-border-width solid @settings[color];
      border-right: @eva-border-width solid @settings[color];
    }
    .eva-border-y {
      border-top: @eva-border-width solid @settings[color];
      border-bottom: @eva-border-width solid @settings[color];
    }
    .eva-border-left {
      border-left: @eva-border-width solid @settings[color];
    }
    .eva-border-right {
      border-right: @eva-border-width solid @settings[color];
    }
    .eva-border-top {
      border-top: @eva-border-width solid @settings[color];
    }
    .eva-border-bottom {
      border-bottom: @eva-border-width solid @settings[color];
    }
  }
}
