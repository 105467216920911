.eva-video-event-list .eva-video__aside-panel {
  width: 200px;
  display: flex;
  flex-direction: column;
}
.eva-video-event-list .eva-video__aside-panel .eva-video__aside-panel-date {
  padding: 10px;
}
.eva-video-event-list .eva-video__aside-panel .eva-video__aside-panel-date .v-text-field__details {
  display: none;
}
.eva-video-event-list .eva-video__aside-panel .eva-video__aside-panel-list {
  max-height: 100%;
  overflow-y: scroll;
}
.eva-video-event-list .eva-video__aside-panel .eva-video__aside-panel-list .v-list {
  padding: 0;
}
.eva-video-event-list .eva-video__aside-panel .eva-video__aside-panel-list .v-list .v-list-item {
  display: flex;
  justify-content: center;
}
.eva-video-event-list .eva-video__aside-panel .eva-repeater {
  padding: 0;
  display: block;
}
.eva-video-event-list .eva-video__aside-panel .eva-repeater .eva-repeater__content {
  margin-bottom: 0;
}
.eva-video-event-list .eva-video__aside-panel .eva-repeater .eva-repeater__content table {
  padding: 0;
}
.eva-video-event-list .eva-video__aside-panel .eva-repeater .eva-repeater__content .eva-default-table-cell {
  display: flex;
  justify-content: center;
}
