
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-label-field {
  margin: 4px 0;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: @eva-padding;
  &.eva-label-field--center {
    align-self: center;
    word-break: break-all;
  }
  span:first-child {
    /*color: #757575;*/
  }
  .eva-label-field--header {
    font-size: 1.2rem;
  }
}
