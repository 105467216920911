.eva-label-field {
  margin: 4px 0;
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
.eva-label-field.eva-label-field--center {
  align-self: center;
  word-break: break-all;
}
.eva-label-field span:first-child {
  /*color: #757575;*/
}
.eva-label-field .eva-label-field--header {
  font-size: 1.2rem;
}
