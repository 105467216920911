
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-files-table {
  display: flex;
  width: 100%;
  height: 100%;
  .eva-images-widget--filter-group {
    margin-right: (@eva-padding * 4);
    display: flex;
    flex-direction: row;
    gap: @eva-padding;
    .eva-images-widget--filter-group-item {
      height: 40px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: @eva-padding;
      border: 2px solid transparent;
      cursor: pointer;
      &:hover {
        border-color: #E4E9EF;
      }
      &.eva-images-widget--filter-group-item--active {
        border: 2px solid #0260CF;
      }
    }
  }
}
