
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-table-field {
  min-height: 0;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  
  .eva-table {
    padding-left: 0;
    padding-right: 0;
    border-radius: 6px;
    > .eva-layout {
      border-radius: 6px;
    }
    &.eva-table-field--dense {
      .eva-repeater-header {
        flex-direction: row;
        .eva-repeater-header__caption {
          margin: auto;
        }
        .eva-text--subheader {
          flex-grow: 1;
        }
        .eva-repeater-header__tools {
          width: auto;
        }
      }
    }
    &.eva-table-field--short {
      .eva-repeater-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        .eva-repeater-header__filter {
          display: none;
        }
        .eva-spacer {
          display: none;
        }
        .eva-repeater-header__list {
          width: 100%;
          .eva-btn {
            width: 100%;
          }
        }
      }
    }
  }
}
