.eva-form-grid-layout {
  display: grid;
}
.eva-form-grid-layout > * {
  margin: 8px !important;
}
.eva-form-grid-layout .eva-input {
  margin: 0;
}
.eva-form-grid-layout.eva-form-layout--border {
  /*padding: @eva-padding;*/
}
.eva-form-grid-layout .eva-form-grid-layout__cell {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.2);
  outline: 1px #90A4AE dashed;
  min-height: 28px;
}
.eva-form-grid-layout .eva-selected-input {
  outline: 2px solid #0260CF;
  box-sizing: border-box;
}
