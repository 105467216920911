
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-field-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;

  .eva-field-wrapper__content {
    width: 100%;
    height: 100%;
  }

  .eva-field-wrapper__info {
    margin-top: 7px;
    margin-left: 5px;
  }
}
