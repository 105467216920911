
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-select-popup {
  display: flex;
  flex-direction: column;
  padding: @eva-padding 0;
  max-height: 300px;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      height: 28px;
      line-height: 28px;
      cursor: pointer;
      padding: 0 (@eva-padding * 2);
      &:hover {
        background-color: #0260CF9F;
      }
      &.eva-select-popup__selected {
        background-color: #0260CF;
      }
    }
  }
}
