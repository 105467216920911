.set-background(@theme, @settings) {
  .eva-theme-@{theme} {
    background-color: @settings[background-0];
    color: @settings[foreground-1];
  }
  .eva-theme-@{theme} {
    .eva-foreground-1 {
      color: @settings[foreground-1]!important;
    }
    .eva-foreground-1 {
      color: @settings[foreground-1]!important;
    }
    .eva-foreground-2 {
      color: @settings[foreground-2]!important;
    }
    .eva-foreground-3 {
      color: @settings[foreground-3]!important;
    }
    .eva-background-5 {
      background-color: @settings[background-5];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-5a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-5a];
      }
    }
    .eva-background-4 {
      background-color: @settings[background-4];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-4a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-4a];
      }
    }
    .eva-background-3 {
      background-color: @settings[background-3];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-3a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-3a];
      }
    }
    .eva-background-2 {
      background-color: @settings[background-2];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-2a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-2a];
      }
    }
    .eva-background-1 {
      background-color: @settings[background-1];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-1a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-1a];
      }
    }
    .eva-background-0 {
      background-color: @settings[background-0];
      color: @settings[foreground-1];
      &.eva-background-hoverable {
        &:hover {
          background-color: @settings[background-0a];
        }
      }
      &.eva-background-active {
        background-color: @settings[background-0a];
      }
    }
  }

  /* core fixes */
  .eva-theme-@{theme} {
    input {
      color: inherit;
    }
    textarea {
      color: inherit;
    }
  }

  /* vuetify fixes */
  .v-application {
    &.eva-theme-@{theme} {

      a {
        color: @settings[foreground-1] !important;
        &:hover {
          opacity: 0.5;
        }
      }

      .v-radio {
        .v-label {
          color: @settings[foreground-1] !important;
          font-size: 14px!important;
        }
        .v-icon {
          color: @settings[foreground-1] !important;
        }
      }

      .theme--light.v-input--selection-controls.v-input--is-disabled:not(.v-input--indeterminate) .v-icon {
        color: @settings[foreground-1] !important;
      }

      .v-tabs {
        &>.v-tabs-bar {
          background-color: @settings[background-2]!important;
        }
        .v-tabs-slider-wrapper {
          color: @settings[foreground-1] !important;
        }
        .v-tab {
          color: @settings[foreground-1] !important;
        }
        .v-tabs-items {
          background-color: @settings[background-2]!important;
        }
      }

      .v-treeview {
        .v-treeview-node__label {
          color: @settings[foreground-1] !important;
        }
        .v-icon {
          color: @settings[foreground-1] !important;
        }
      }
    }
  }
}
