.eva-image {
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
}
.eva-image img {
  margin: auto;
  object-fit: fill;
  max-width: 100%;
  max-height: 100%;
}
.eva-image.eva-image--cover img {
  margin: auto;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
}
.eva-image.eva-image--round {
  border-radius: 50%;
}
.eva-image.eva-image--round img {
  border-radius: 50%;
}
