.eva-drawer--close .eva-list .eva-list__item .eva-list__item-header {
  display: none;
}
.eva-drawer--close .eva-list .eva-list__item .eva-list__item-children {
  display: none;
}
.eva-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.eva-list a {
  opacity: 1!important;
}
.eva-list .eva-list__item {
  padding: 0 8px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: inherit;
  text-decoration: none;
}
.eva-list .eva-list__item .eva-list__item-children {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.eva-list .eva-list__item .eva-list__item-children .eva-list__item-inner {
  margin-left: 24px;
}
.eva-list .eva-list__item .eva-list__item-inner {
  height: 32px;
  white-space: nowrap;
  /*border-radius: 6px;*/
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.eva-list .eva-list__item .eva-list__item-inner:hover {
  /*background-color: #E5F1FF;*/
}
.eva-list .eva-list__item.eva-list__item--active {
  color: white;
}
.eva-list .eva-list__item.eva-list__item--active .eva-list__item-inner {
  background-color: #0260CF;
}
.eva-list .eva-list__divider {
  flex: 0 0 auto;
  display: flex;
  width: 100%;
  /*height: @eva-border-width;*/
  /*background-color: @eva-border-color;*/
}
