
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-catalog-ref-field {
  min-width: 0;

  .v-menu {
    .v-menu__content {
      max-width: 100%!important;
    }
  }

  .eva-textbox__input {
    height: unset!important;
    min-height: 24px;
    overflow-x: hidden;
  }
  .eva-textbox__input-inner {
    .eva-catalog-ref-field__list {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      .eva-catalog-ref-field__chip {
        background-color: #0260CF !important;
        color: white !important;
        height: 24px;
        margin: 0!important;
        .v-chip__close {
          margin: 0 !important;
        }
        .v-chip__content {
          gap: .75rem;
          width: 100%;
        }
        .v-icon {
          font-size: 16px !important;
        }
      }
    }
    &.eva-textbox__input-inner--no-wrap {
      .eva-catalog-ref-field__list {
        flex-wrap: nowrap;
        .v-chip {
          overflow: unset;
        }
      }
    }
    &.eva-textbox__input-inner--column {
      .eva-catalog-ref-field__list {
        flex-direction: column;
      }
    }
  }

  .eva-catalog-ref-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;
    padding: @eva-padding;
  }

  .eva-catalog-ref__list-item {
    padding-left: @eva-padding * 2;
    padding-top: (@eva-padding / 2);
    padding-bottom: (@eva-padding / 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .v-icon {
      font-size: 16px!important;
    }
    &:hover {
      background-color: #263444;
    }
  }

  .eva-catalog-ref__list-item__active {
    background-color: #d2d8dd!important;
  }

  .eva-catalog-ref__list-item__text-icon {
    margin-left: 10px;
  }

  .eva-catalog-ref__list-item__text-no-icon {
    margin-left: 26px;
  }

  .eva-catalog-ref__list-item__actions {
    display: flex;
    flex-direction: row;
    gap: (@eva-padding / 1);
    padding-right: @eva-padding;
  }

  .eva-catalog-ref-list-footer {
    display: flex;
    justify-content: center;
  }

  .v-select__selections {
    input {
      display: none;
    }
  }
}
