.eva-progress {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.eva-progress .eva-icon {
  font-size: 64px;
  width: 98px!important;
  height: 98px!important;
}
.eva-progress .eva-icon:before {
  animation: mdi-spin 0.7s infinite linear;
}
