
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             @import "common";
@import "text";
@import "scrolls";
@import "borders";
@import "layouts";
@import "tooltip";

@import "functions/background";
@import "functions/borders";

.set-background(light, {
  foreground-1: #3E4C5D;
  foreground-2: #3E4C5D;
  foreground-3: #3E4C5D;
  background-5: white;
  background-4: white;
  background-3: white;
  background-2: white;
  background-1: white;
  background-0: white;
  background-5a: white;
  background-4a: white;
  background-3a: white;
  background-2a: white;
  background-1a: white;
  background-0a: white;
});
.set-background(dark, {
  foreground-1: #E4E9EF;
  foreground-2: #ACBBCB;
  foreground-3: #7B8B9D;
  background-5: #576D88;
  background-4: #495A6F;
  background-3: #3E4C5D;
  background-2: #2A3746;
  background-1: #212D39;
  background-0: #181F27;
  background-5a: #5E7694;
  background-4a: #506783;
  background-3a: #405165;
  background-2a: #324459;
  background-1a: #263444;
  background-0a: #181F27;
});

.set-border(light, {
  color: #E4E9EF;
});
.set-border(dark, {
  color: #495A6F;
});

@import "design/styles";

.vue-tooltip {
  z-index: 10000;
}

.eva-btn {
  font-weight: 400;
  .eva-btn__icon {
    font-size: 16px;
  }
}

.eva-btn-commands {
  font-weight: 400;
  .eva-btn-commands__icon {
    font-size: 16px;
  }
}

.eva-chip {
  .eva-chip__inner {
    width: 100%;
  }
  .eva-chip__text {
    font-weight: 400;
    line-height: 18px;
  }
  .eva-chip__button {
    /*font-size: 16px;*/
  }
}

.eva-btn--small {
  padding: 8px 8px;
}

.eva-btn--large {
  height: 48px;
  flex-shrink: 0;
}

.eva-textbox__button {
  /*color: #3E4C5D;*/
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.eva-textarea__button {
  font-size: 16px;
}

.eva-base-field__field,
.eva-textbox__input,
.eva-textarea__field,
.eva-checkbox__text {
  font-weight: 400;
}

.theme--light.v-icon {
  color: unset;
}




