.eva-jsonarea-field {
  width: 100%;
}
.eva-jsonarea-field .v-input {
  min-height: 200px;
  background-color: #E5F1FF;
}
.eva-jsonarea-field .v-input__slot {
  background-color: #E5F1FF !important;
}
.eva-jsonarea-field .v-select__selections {
  padding: 8px 0 0!important;
}
.eva-jsonarea-field .v-select__selections > div {
  margin-left: -10px;
  width: calc(100% + 10px);
  height: 100%;
  min-height: 200px;
}
.eva-jsonarea-field .v-select__selections input {
  display: none;
}
.eva-jsonarea-field .v-input,
.eva-jsonarea-field .v-input__control,
.eva-jsonarea-field .v-input__slot,
.eva-jsonarea-field .v-select__selections {
  height: 100%!important;
}
.eva-jsonarea-field .v-input__control {
  flex-wrap: nowrap!important;
}
.eva-jsonarea-field .v-input__slot {
  padding-right: 2px!important;
  padding-bottom: 2px!important;
}
.eva-jsonarea-field .v-input__slot fieldset {
  background-color: #F5F5F5 !important;
}
.eva-jsonarea-field .v-input__append-inner {
  display: none;
}
.eva-jsonarea-field .jsoneditor-menu {
  display: none;
}
.eva-jsonarea-field .jsoneditor {
  border: none;
}
.eva-jsonarea-field .ace_scroller {
  background-color: #F5F5F5 !important;
}
.eva-jsonarea-field .jsoneditor-vue {
  height: 100%;
}
.eva-jsonarea-field .jsoneditor-tree {
  min-height: 100%;
}
.eva-jsonarea-field .jsoneditor-outer {
  min-height: 100%;
  margin: 0;
  padding: 0;
}
