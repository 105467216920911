.eva-tree-registry {
  display: flex;
  flex-direction: column;
  /*color: #3E4C5D;*/
  min-height: 0;
  height: 100%;
}
.eva-tree-registry .selectBlock {
  background-color: #2A3746;
}
.eva-tree-registry .selectBlock .childBlock {
  background-color: #324459;
}
.eva-tree-registry .eva-tree-header {
  padding: 16px 16px 8px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  gap: 8px;
}
.eva-tree-registry .eva-tree-header .eva-tree-header__context {
  width: 100%;
}
.eva-tree-registry .eva-tree-header .eva-tree-header__tools {
  height: 50px;
  align-items: center;
}
.eva-tree-registry .eva-tree-inner {
  padding: 1px 3px 50px 1px;
  /* padding: 2px (@eva-padding * 2); */
}
