
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-jsonarea {
  min-height: 300px;
  .eva-textbox__inner{
    padding-left: 1px;
  }
  .eva-textbox__input {
    height: unset;
  }
  &.eva-textbox--error {
    .eva-textbox__inner:hover {
      .eva-jsonarea--editor {
        .ace_scroller {
          background-color: #E5F1FF!important;
        }
        .ace_gutter {
          background-color: #d2e7ff!important;
        }
        .ace_gutter-active-line {
          background-color: #bedcff!important;
        }
      }
    }
    .eva-jsonarea--editor {
      .ace_scroller {
        background-color: #FFF9F9 !important;
      }
      .ace_gutter {
        background-color: #ffe5e5 !important;
      }
      .ace_gutter-active-line {
        background-color: #ffd1d1 !important;
      }
    }
  }
  .eva-jsonarea--editor {
    height: 100%;
    width: 100%;
    z-index: 1;
    .ace_scroller {
      background-color: #E5F1FF!important;
    }
    .ace_gutter {
      background-color: #d2e7ff!important;
    }
    .ace_gutter-active-line {
      background-color: #bedcff!important;
    }
    .jsoneditor-menu {
      display: none;
    }
    .jsoneditor {
      border: none;
    }
    .jsoneditor-vue {
      height: 100%;
    }
    .jsoneditor-tree{
      min-height: 100%;
    }
    .jsoneditor-outer{
      min-height:100%;
      margin: 0;
      padding: 0;
    }
    .jsoneditor-readonly {
      display: none;
    }
  }
}
