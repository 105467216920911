
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-tree-registry {
  display: flex;
  flex-direction: column;
  /*color: #3E4C5D;*/
  min-height: 0;
  height: 100%;

  .selectBlock {
    background-color: #2A3746;

    .childBlock {
      background-color: #324459;

    }

  }

  .eva-tree-header {
    padding: (@eva-padding * 2) (@eva-padding * 2) @eva-padding;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    gap: @eva-padding;

    .eva-tree-header__context {
      width: 100%;
    }

    .eva-tree-header__tools {
      height: @eva-header;
      align-items: center;
    }
  }

  .eva-tree-inner {
    padding: 1px 3px 50px 1px;
    /* padding: 2px (@eva-padding * 2); */
  }
}
