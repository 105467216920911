
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-file-attach-field {
  & &-btn {
    width: 100%;
    margin-top: 12px;
  }

  & &-files {
    display: flex;
    flex-direction: column;

    & .eva-file-attach-field-files-file {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .eva-file-attach-field-files-file-name {
        display: flex;
        align-items: center;
      }
    }
    
    & .eva-file-attach-field-files-images {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      & .eva-file-attach-field-files-file {
        width: 100px;
        height: 76px;
        position: relative;

        & .eva-file-attach-field-files-file-delete-btn {
          position: absolute;
          right: -5px;
          top: 0;
          border-radius: 50%;
          background-color: #495A6F;
        }

        & .eva-file-attach-field-files-file-delete-btn:hover {
          cursor: pointer;
        }
      }
    }
  }
}
