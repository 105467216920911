@import "variables.less";

.libs {
  &__list {
    list-style: none;
    padding: 60px 15px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 24px;

    a {
      color: @cBlue;
      text-decoration: none;
      transition: color @transition-time;

      &:hover {
        color: @cBlueHover;
      }
    }
  }
}

.lib-eva {
  &__container {
    padding: 60px 15px;
    max-width: 1200px;
    margin: 0 auto;
  }

  &__title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
  }

  &__content {
    max-width: 640px;
  }

  &__variant {
    margin-bottom: 40px;
  }
}

.lib-eva-btn {

  &__block {
    display: flex;
    flex-wrap: wrap;

    & + & {
      margin-top: 40px;
    }

    &-title {
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }

  &__variant {
    min-width: 250px;
    margin-right: 40px;
    margin-bottom: 20px;

    &-title {
      font-weight: 600;
      margin-bottom: 4px;
    }
  }
}