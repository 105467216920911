
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-command-list {
  display: flex;
  gap: @eva-padding;
  &.eva-command-list--no-gap {
    gap: 0;
  }
  &.eva-command-list--column {
    flex-direction: column;
    .eva-btn {
      .v-btn__content {
        justify-content: flex-start;
      }
    }
  }
}
