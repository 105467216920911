.eva-repeater-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-items: left;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  gap: 8px;
  padding: 0 16px 16px;
}
.eva-repeater-footer .eva-pagination {
  display: flex;
  flex-direction: row;
}
.eva-repeater-footer .eva-pagination .eva-btn {
  border: none;
  /*color: rgba(0,0,0,.54);*/
}
.eva-repeater-footer .eva-pagination .eva-btn.eva-btn--secondary {
  /*background-color: #F6F8FF;
        color: #0260CF;*/
  /*font-weight: 600;*/
}
.eva-repeater-footer .eva-repeater-footer__select {
  width: 130px;
  margin: 0!important;
  flex-grow: 0;
  border-radius: 8px !important;
}
.eva-repeater-footer .eva-repeater-footer__select input {
  display: none!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-input__append-inner {
  margin-top: 6px;
}
.eva-repeater-footer .eva-repeater-footer__select .v-input__slot {
  margin: 0!important;
  min-height: 36px!important;
  padding: 0!important;
  background-color: #2A3746 !important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-input__slot fieldset {
  border-color: #2A3746;
}
.eva-repeater-footer .eva-repeater-footer__select .v-icon {
  color: #E4E9EF !important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-text-field__details {
  display: none!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-select__selections {
  padding: 0!important;
  justify-content: center!important;
}
.eva-repeater-footer .eva-repeater-footer__select .v-icon {
  /*color: #3E4C5D!important;*/
}
.eva-repeater-footer .eva-repeater-footer__select .v-select__selection {
  margin: 0!important;
  /*color: #3E4C5D;*/
  /*font-weight: 600;*/
  color: #E4E9EF !important;
}
