html {
  overflow: hidden;
}

.eva-tooltip--info {
  &.v-popper__popper--hide-to {
    /*display: none;*/
  }

  .v-popper__inner {
    max-width: 300px;
    border: 1px solid #BDBDBD;
    background: #BDBDBD;
    color: #3E4C5D;
  }
  .v-popper__arrow-inner {
    border-color: #BDBDBD;
  }
  .v-popper__arrow-outer {
    border-color: #BDBDBD;
  }
}

.eva-tooltip--primary {
  &.v-popper__popper--hide-to {
    /*display: none;*/
  }
  .v-popper__inner {
    max-width: 300px;
    border: 1px solid #006CEB;
    background: #006CEB;
    color: #FFFFFF;
  }
  .v-popper__arrow-inner {
    border-color: #006CEB;
  }
  .v-popper__arrow-outer {
    border-color: #006CEB;
  }
}



.eva-tooltip--error {
  .v-popper__inner {
    max-width: 300px;
    border: 1px solid #EF9A9A;
    background: #EF9A9A;
    color: #3E4C5D;
  }
  .v-popper__arrow-inner {
    border-color: #EF9A9A;
  }
  .v-popper__arrow-outer {
    border-color: #EF9A9A;
  }
}

*[data-popper-shown] {
  &:hover {
    cursor: pointer;
  }
  cursor: pointer;
}
