@cCheckboxHover: #006CEB;

.eva-checkbox {
  line-height: 1;

  &__label {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 16px;
    cursor: pointer;
    min-height: 16px;

    @media @minHover {
      &:hover {
        .eva-checkbox {
          &__icon {
            /*color: @cCheckboxHover;
            border-color: @cCheckboxHover;*/

            rect,
            path {
              /*stroke: @cCheckboxHover;*/
            }
          }
        }
      }
    }
  }

  &__text {
    display: inline-block;
    vertical-align: top;
    padding-left: 10px;
    font-weight: 600;
    /*color: @cBlackText;*/
    font-size: 14px;
    line-height: 1;
    padding-top: 2px;

    &:empty {
      padding-left: 0;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 3px;
    /*border: 1.5px solid @cBlue;
    color: @cBlue;*/
    border: 1.5px solid #E4E9EF;
    color: #E4E9EF;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    transition: color @transition-time, border-color @transition-time;
    &::before{
      /*opacity: 0;*/
    }

    svg {
      display: none;
    }

    rect,
    path {
      transition: all @transition-time;
    }

    path {
      /*opacity: 0;*/
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;

    &:disabled {

    }

    &:checked {
      & ~ .eva-checkbox {
        &__icon {
          &:before{
            /*opacity: 1;*/
          }
          path {
            /*opacity: 1;*/
          }
        }
      }
    }

    /*opacity: 0;*/
  }

  &--error {
    .eva-checkbox {
      &__icon {
        color: @cError;
        border-color: @cError;

        rect,
        path {
          stroke: @cError;
        }
      }

      &__label {
        color: @cError;
        @media @minHover {
          &:hover {
            .eva-checkbox {
              &__icon {
                rect,
                path {
                  stroke: @cErrorHover;
                }
              }
            }
          }
        }
      }
    }
  }

  &--disabled {
    .eva-checkbox {
      &__icon {
        color: @formFon;
        border-color: @formFon;

        rect,
        path {
          stroke: @formFon;
        }
      }

      &__label {
        cursor: default;
        @media @minHover {
          &:hover {
            .eva-checkbox {
              &__icon {
                color: @formFon;
                border-color: @formFon;
                rect,
                path {
                  stroke: @formFon;
                }
              }
            }
          }
        }
      }
    }
  }
}
