.eva-video-archive-teleport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.eva-video-archive-teleport .eva-video__main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header-background {
  height: 40px;
  width: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.3;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header {
  height: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header .eva-video__header-left {
  max-width: 65%;
  color: white;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header .eva-video__header-left .v-btn {
  max-width: 100%;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header .eva-video__header-left .v-btn .v-btn__content {
  width: 100%;
  justify-content: start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header .eva-video__header-right {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__header .eva-video__header-right .eva-video__header-clock {
  text-align: center;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__video {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__error {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.eva-video-archive-teleport .eva-video__main .eva-video__main-container .eva-video__footer {
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-timeline {
  position: relative;
  height: 21px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-timeline .timeline-list {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  position: absolute;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-timeline .timeline-list .timeline-list-div {
  cursor: default;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently  supported by Chrome, Edge, Opera and Firefox */
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-timeline .timeline-marker {
  width: 2px;
  height: 21px;
  background-color: red;
  position: absolute;
  cursor: pointer;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-timeline .eva-video__archive-canvas {
  width: 100%;
  height: 21px;
  background-color: black;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slider {
  max-width: 150px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slot {
  margin: 0;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slot .v-slider {
  margin: 0;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slot .v-slider .v-slider__thumb::after {
  width: 32px;
  height: 32px;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slot .v-slider .v-slider__tick {
  border-radius: 50%;
  background-color: #181f27;
  cursor: pointer;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-input__slot .v-slider .v-slider__ticks-container {
  background-color: #181f27;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-left .v-messages {
  display: none;
}
.eva-video-archive-teleport .eva-video__main .eva-video__archive-footer .eva-video__archive-footer-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.eva-video-archive-teleport .eva-video__aside-panel {
  width: 140px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.eva-video-archive-teleport .eva-video__aside-panel .eva-video__aside-panel-date {
  padding: 0 8px;
}
.eva-video-archive-teleport .eva-video__aside-panel .eva-video__aside-panel-date .v-text-field__details {
  display: none;
}
.eva-video-archive-teleport .eva-video__aside-panel .eva-video__aside-panel-list {
  min-height: 0;
  padding: 8px 0 0;
}
.eva-video-archive-teleport .eva-video__aside-panel .eva-video__aside-panel-list .eva-default-table-cell {
  text-align: center;
}
.eva-video-archive-teleport video::-webkit-media-controls {
  display: none !important;
}
.eva-video-archive {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
