
             @eva-header: 50px;
@eva-padding: 8px;
@eva-icon-size: 34px;
@eva-grid-gap: 2px;
@eva-border-width: 1px;
@eva-info-color: #2196F3;
@eva-success-color: #4CAF50;
@eva-warning-color: #FFC107;
@eva-error-color: #F44336;
@eva-default-color: #607D8B;

             
.eva-media-text {
  display: flex;
  gap: @eva-padding;
  flex: 0 0 auto;
  .eva-icon {
    width: 32px;
    height: 32px;
    display: flex;
    margin: auto 0;
    flex: 0 0 auto;
  }
  .eva-image {
    width: 32px;
    height: 32px;
    display: flex;
    margin: auto 0;
    flex: 0 0 auto;
  }
}
